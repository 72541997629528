<template>
  <div class="theme_one_block">
    <div class="theme_one_header_block">
      <div class="theme_one_header_container">
        <div class="theme_one_header_label">
          Финансовая грамотность и как ее повысить? Эссе
        </div>
      </div>
    </div>
    <div class="theme_one_main_block">
      <div class="theme_one_main_container">
        <div class="theme_one_content_container">
          <a class="theme_one_content_container_btn_back">
            <div class="theme_one_content_container_btn_text">Назад</div>
          </a>
          <div class="theme_one_plot">
            <div class="theme_one_exercises_plot_theme_active">
              <div class="theme_one_plot_theme_active_label">Тема 1</div>
              <div class="theme_one_plot_theme_active_inactive_article">Советы по изучению языка</div>
              <div class="theme_one_plot_theme_active_inactive_video">Особенности диалектов в ...</div>
              <div class="theme_one_plot_theme_active_inactive_slide">Произношение отдельных...</div>
              <div class="theme_one_plot_theme_active_selected">Упражнения</div>
            </div>
            <a href="#" class="theme_one_plot_theme">
              Тема 2
            </a>
            <a href="#" class="theme_one_plot_theme">
              Тема 3
            </a>
            <a href="#" class="theme_one_plot_theme">
              Тема 4
            </a>
            <a href="#" class="theme_one_plot_theme">
              Тестирование
            </a>
          </div>
        </div>
        <div class="theme_one_exercises_desc">
          <div class="theme_one_exercises_desc_text_container">
            <div class="theme_one_desc_title">
              Упражнение 4
            </div>
            <div class="theme_one_desc_text">
              Напишите Эссе на тему “Books & Films” применив изученную лексику. Минимальное количество слов: 300
            </div>
            <div class="theme_one_desc_essay">
              The latest advances in information technology make people think that schools of the future will use
              computers instead of printed books. Although electronic books have not been widely accepted yet, they have
              certain advantages over traditional paper volumes. But will they be able to replace printed books? In my
              opinion, students will be widely using computers for studying in the future. To begin with, computers can
              store lots of books in their memory and modern software allows us to find quickly the necessary
              information. Besides, with the interactive programs on computers studying will be much more exciting. What
              is more, electronic books will not degrade overtime like their printed counterparts. On the other hand,
              lots of disbelievers argue that computers will not replace printed books because a printed book is better
              for human eyes than a computer screen. In addition, books are cheaper and easier to use since they do not
              need electricity or the Internet connection. I cannot agree with this because modern computer screens emit
              no radiation and allow us to read even in low light conditions. Of course we will have to pay for
              electricity but I think it will be cheaper than to pay for printed books, which are very expensive
              nowadays.
            </div>
            <div class="theme_one_desc_btn">
              <a href="#" class="theme_one_desc_btn_back">Сохранить ответ</a>
              <a href="#" class="theme_one_desc_btn_next">Далее</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ThemeOne"
}
</script>

<style scoped>

</style>